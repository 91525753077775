var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',{attrs:{"accent-color":"primary"}},[_c('CCardHeader',{attrs:{"color":"white"}},[_c('b',{staticClass:"text-dark"},[_vm._v(_vm._s(_vm.tableTitle))])]),_c('CCardBody',[_c('BlockColumn',[_c('BlockItem',{attrs:{"size":'1-2'}},[_c('BlockInfoDatePicker',{attrs:{"title":_vm.$t('date'),"disabled":false},model:{value:(_vm.selectedDate),callback:function ($$v) {_vm.selectedDate=$$v},expression:"selectedDate"}})],1)],1),_c('CDataTable',{staticClass:"mb-0 table-outline",attrs:{"hover":"","items":_vm.tableItems,"fields":_vm.tableFields,"head-color":"light","external":"","items-per-page-select":{label: _vm.$t('items_page')},"items-per-page":_vm.perPage,"currentPage":_vm.currentPage,"loading":_vm.loading},on:{"pagination-change":_vm.handlePagination},scopedSlots:_vm._u([{key:"plate",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.license_plate ? item.license_plate : "")+" ")])]}},{key:"homePlantId",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.home_plant_external_id ? item.home_plant_external_id : "")+" ")])]}},{key:"homePlant",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.home_plant_name ? item.home_plant_name : "")+" ")])]}},{key:"callInStatus",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.call_in_status ? _vm.$t(item.call_in_status) : "")+" ")])]}},{key:"callInPlantId",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.call_in_plant_external_id ? item.call_in_plant_external_id : "")+" ")])]}},{key:"callInPlant",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.call_in_plant_name ? item.call_in_plant_name : "")+" ")])]}},{key:"callInTime",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.call_in_time ? item.call_in_time : "")+" ")])]}},{key:"no-items-view",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center justify-content-center my-3"},[_c('span',{staticClass:"mr-2",staticStyle:{"font-size":"30px","font-weight":"600"}},[_vm._v(_vm._s(_vm.$t('no_items')))]),_c('CIcon',{staticClass:"text-danger",staticStyle:{"width":"35px","height":"35px"},attrs:{"name":"cil-ban"}})],1)]},proxy:true}])}),_c('CPagination',{attrs:{"activePage":_vm.currentPage,"pages":_vm.totalPages,"active-page":_vm.currentPage},on:{"update:activePage":[function($event){_vm.currentPage=$event},_vm.loadData],"update:active-page":function($event){_vm.currentPage=$event}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }